import { DependencyList, useContext, useEffect, useReducer } from "react";
import { AuthorizationError } from "contexts/api/AuthorizationError";
import apiContext, { FetchExtra } from "contexts/api/context";
import { FetchError } from "contexts/api/FetchError";
import { HttpError } from "contexts/api/HttpError";

export const defaultIApiState = {
  isLoading: false,
  isError: false,
  sendCount: 0,
  data: {},
};

export type ApiResponseFieldErrors<T> = {
  [K in keyof T]: string;
} & {
  composed?: string;
};

type TApiError = Error & AuthorizationError & FetchError;
export interface IApiError extends TApiError {}

export interface IApiReducerState<
  ResponseBodyType,
  RequestBodyType = ResponseBodyType
> {
  isLoading: boolean;
  isError: boolean;
  error?: IApiError;
  data: ResponseBodyType;
  statusCode?: number;
  sendCount: number;
  fieldErrors?: ApiResponseFieldErrors<RequestBodyType>;
}

export interface IUseApiConfig {
  /**
   * Shortcut for setting requestOptions.cache = "no-cache". Causes intercepts to bypass request.
   */
  noCache?: boolean;
  dependencies?: DependencyList;
  requestOptions?: Partial<RequestInit>;
  noDefaultContentType?: boolean;
  fetchExtra?: FetchExtra;
}

export interface IApiState<ResponseBodyType, RequestBodyType = ResponseBodyType>
  extends IApiReducerState<ResponseBodyType, RequestBodyType> {}

export const defaultRequestOptions: RequestInit = {};
const defaultContentType = "application/json";

export default function useApi<
  ResponseBodyType,
  RequestBodyType = ResponseBodyType
>(
  url?: string | false | null,
  initialData?: ResponseBodyType,
  config: IUseApiConfig = {}
): IApiState<ResponseBodyType, RequestBodyType> {
  const normalizedUrl = url && url.charAt(0) === "/" ? url.slice(1) : url;
  const [state, dispatch] = useReducer(apiFetchReducer, {
    isLoading: false,
    isError: false,
    data: initialData,
    sendCount: 0,
  });
  const ctx = useContext(apiContext);

  const {
    dependencies = [],
    noDefaultContentType,
    requestOptions = defaultRequestOptions,
    noCache,
    fetchExtra = {},
  } = config;

  useEffect(() => {
    if (!normalizedUrl) {
      return;
    }
    let mounted = true;
    let dispatchSafe = (action: IApiFetchAction<ResponseBodyType>) =>
      dispatch(action);
    dispatchSafe({ type: "INIT", payload: initialData });
    const abortController = new AbortController();
    (async () => {
      if (!mounted) {
        return;
      }
      let statusCode;
      try {
        dispatchSafe({ type: "FETCH_INIT" });
        // Add default headers if not present
        const headers = new Headers(requestOptions.headers || {}); // Edge will puke on undefined because f*!@ you, that's why.
        if (
          !requestOptions.headers &&
          !headers.has("Content-Type") &&
          !noDefaultContentType
        ) {
          headers.set("Content-Type", defaultContentType);
        }
        requestOptions.headers = headers;

        // Will cause intercepts to bypass this request
        if (noCache) {
          requestOptions.cache = "no-cache";
        }
        const body = await ctx
          .fetch<ResponseBodyType>(
            normalizedUrl,
            {
              signal: abortController.signal,
              ...requestOptions,
            },
            fetchExtra
          )
          .then((resp) => {
            statusCode = resp.status;
            return resp;
          })
          .then((resp) => {
            return ctx.parseResponse<ResponseBodyType>(
              normalizedUrl,
              requestOptions,
              resp,
              fetchExtra
            ); //returns only the body
          })
          .catch((err) => {
            throw err;
          });

        dispatchSafe({ type: "FETCH_SUCCESS", payload: body, statusCode });
      } catch (err) {
        if (
          err &&
          typeof err === "object" &&
          "name" in err &&
          err.name !== "AbortError"
        ) {
          // console.warn(`useApi: [${error.name}] [${normalizedUrl}]: ${error.toString()}`)
        }

        if (err instanceof HttpError) {
          statusCode = err.status;
          return dispatchSafe({
            type: "FETCH_FAILURE",
            payload: err,
            statusCode,
            fieldErrors: undefined,
          });
        }

        const error = err as FetchError;
        statusCode = error.response && error.response.status;
        const fieldErrors =
          statusCode && [400, 404, 409].includes(statusCode)
            ? error.body
            : undefined;

        return dispatchSafe({
          type: "FETCH_FAILURE",
          payload: error,
          statusCode,
          fieldErrors: fieldErrors,
        });
      }
    })();
    const cleanup = () => {
      mounted = false;
      abortController.abort();
      dispatchSafe = () => null; // we should not dispatch after cleanup.
      dispatch({ type: "INIT", payload: initialData });
    };
    return cleanup;
  }, [normalizedUrl, ...dependencies]); // eslint-disable-line react-hooks/exhaustive-deps

  return state as IApiState<ResponseBodyType, RequestBodyType>;
}

export interface IApiFetchAction<T> {
  type: string;
  payload?: any;
  statusCode?: number;
  fieldErrors?: ApiResponseFieldErrors<T>;
}
export const apiFetchReducer = <T,>(
  state: IApiReducerState<T>,
  action: IApiFetchAction<T>
): IApiReducerState<T> => {
  const { type, payload, statusCode, fieldErrors } = action;
  switch (type) {
    case "INIT":
      return {
        data: payload,
        isLoading: false,
        isError: false,
        error: undefined,
        statusCode,
        sendCount: 0,
      };
    case "FETCH_INIT":
      return {
        ...state,
        isLoading: true,
        sendCount: state.sendCount + 1,
      };
    case "FETCH_SUCCESS":
      return {
        ...state,
        isLoading: false,
        isError: false,
        data: payload,
        error: undefined,
        statusCode,
      };
    case "FETCH_FAILURE": {
      const error = payload;
      return {
        ...state,
        isLoading: false,
        isError: true,
        error,
        statusCode,
        ...(fieldErrors ? { fieldErrors } : {}),
      };
    }
    default:
      throw new Error(`Unhandled reducer type encountered: "${type}"`);
  }
};
